import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let Tabs = class Tabs extends Vue {
    constructor() {
        super(...arguments);
        /**
         * All possible tabs
         */
        this.allTabs = [
            {
                label: this.$t('modules.profile.components.tabs.tabs.my-data'),
                target: { name: 'profile.my-data' }
            },
            // {
            //   label: this.$t('modules.profile.components.tabs.tabs.usercom'),
            //   target: { name: 'profile.usercom' }
            // },
            {
                label: this.$t('modules.profile.components.tabs.tabs.subscription'),
                target: { name: 'profile.subscription' }
            },
            {
                label: this.$t('modules.profile.components.tabs.tabs.articles'),
                target: { name: 'profile.articles' }
            }
        ];
    }
    /**
     * Tabs to be rendered
     */
    get tabs() {
        return [...this.allTabs].filter((tab) => {
            if (!this.showSubscriptionBtn) {
                return tab.label !== this.$t('modules.profile.components.tabs.tabs.subscription');
            }
            return tab;
        });
    }
};
__decorate([
    Prop({ type: Boolean, required: false, default: true })
], Tabs.prototype, "showSubscriptionBtn", void 0);
Tabs = __decorate([
    Component({ name: 'Tabs' })
], Tabs);
export { Tabs };
export default Tabs;
