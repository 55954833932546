import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ModalType } from '@movecloser/front-core';
import { EModal } from '@contract/modal';
import { NavigationServiceType } from '@service/navigation';
import { Inject } from '@plugin/inversify';
import { Screen } from '@component/Screen';
import { SubscriptionsRepositoryType } from '@subscription/contracts';
import { Tabs } from '../../components/Tabs';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let MyAccountView = class MyAccountView extends Vue {
    /**
     * Determines whether the component is still loading data.
     */
    get isLoading() {
        return this.isWaitingForAuth || !this.isUserLoggedIn;
    }
    /**
     * Determines whether the User is currently logged-in.
     */
    get isUserLoggedIn() {
        return this.$store.getters['profile/isUserLoggedIn'];
    }
    /**
     * Determines whether the component is still waiting for the response from the `AuthorizationService`.
     */
    get isWaitingForAuth() {
        return this.$store.getters['profile/isWaitingForAuth'];
    }
    get me() {
        return this.$store.getters['profile/me'];
    }
    get shouldRenderSubscriptionBtn() {
        return this.navigation.getSubscriptionButtonState();
    }
    refresh() {
        this.getSubscription();
    }
    redirect() {
        this.$router.push('/');
    }
    triggerLogIn() {
        this.modalConnector.open(EModal.LogIn, {}, { closable: false });
    }
    getSubscription() {
        if (!this.me || !this.me.subscription) {
            return;
        }
        this.subscriptions.load(this.me.subscription).then(response => {
            this.$store.commit('profile/SET_SUBSCRIPTION', response);
        }).catch(e => {
            console.warn(e);
        });
    }
    onIsWaitingForAuthChange(isWaitingForAuth, oldValue) {
        if (oldValue !== isWaitingForAuth && !isWaitingForAuth && !this.isUserLoggedIn) {
            this.triggerLogIn();
        }
        else {
            this.getSubscription();
        }
    }
};
__decorate([
    Inject(ModalType)
], MyAccountView.prototype, "modalConnector", void 0);
__decorate([
    Inject(NavigationServiceType)
], MyAccountView.prototype, "navigation", void 0);
__decorate([
    Inject(SubscriptionsRepositoryType)
], MyAccountView.prototype, "subscriptions", void 0);
__decorate([
    Watch('isWaitingForAuth')
], MyAccountView.prototype, "onIsWaitingForAuthChange", null);
MyAccountView = __decorate([
    Component({
        name: 'MyAccountView',
        components: {
            Screen,
            Tabs
        },
        metaInfo() {
            return { title: `${this.$t('modules.profile.components.tabs.tabs.my-data')}` };
        },
        created() {
            this.getSubscription();
        },
        mounted() {
            if (!this.isWaitingForAuth && !this.isUserLoggedIn) {
                this.triggerLogIn();
            }
        }
    })
], MyAccountView);
export { MyAccountView };
export default MyAccountView;
